import { storeCollection } from '../../services/firebase';
import { ordersCollection} from '../../services/firebase';
export default {
    state: () => ({
        store: null,
        allOrders: null,
    }),
    mutations: {
        setStore(state, data) {
            state.store = data;
        },
        setAllOrders(state, data) {
            state.allOrders = data;
        }
    },
    actions: {
        loadAllOrders(context) {
            // const items = [
            //   { name:'Frappuccino', description:'siurfgbhaelkrfuibgh', id: '1'},
            //   { name:'Frappuccina', description:'siurfgbhaelkrfuibgh', id: '2'},
            // ];
            console.log('loadAllOrders');
            const fillData = qs => {
                const orders = [];

                console.log('fillAllOrderData')
                qs.forEach((doc) => {
                    const order = doc.data();
                    order.id = doc.id;
                    orders.push(order);
                });
                context.commit('setAllOrders', orders);
                console.log(`loaded ${orders.length} all orders`);
                //console.log('filled');
            };
            var startDate = new Date(new Date().setDate(new Date().getDate() - 7));
            console.log('loading last 7 days: ' + startDate);

            ordersCollection.where('createdDate', '>', startDate).get().then(fillData);
            ordersCollection.where('createdDate', '>', startDate).onSnapshot(fillData)
        },
        loadStore(context) {            
            var fillData = qs => {
              let store = {};
              qs.forEach((doc) => {
                  store = doc.data();
                  store.id = doc.id;
              });
              context.commit('setStore', store);
            };
            storeCollection.get('DailyGrind').then(fillData);
            storeCollection.onSnapshot(fillData);
          },
        toggleStoreOpen(context, isOpen) {
             storeCollection.doc('DailyGrind').set({
              isOpen: isOpen
             }, {merge: true});
         },
         changeMaxItems(context, maxItemsPerOrder) {
            storeCollection.doc('DailyGrind').set({
            maxItemsPerOrder: Number(maxItemsPerOrder)
            }, 
            {merge: true});
         },
         togglePaidStatus(context, payload) {
            ordersCollection.doc(payload.orderId).set({
                paid: payload.paid
            }, {merge: true});
         },
         toggleMaxOrders(context, maxItemsPerDay) {
            storeCollection.doc('DailyGrind').set({
             maxOpenOrders: Number(maxItemsPerDay)
            }, {merge: true});
        }
        //getQueue(context, data) {
            //retrieves current status of orders
        //},

        // setUser({commit}, data) {
        //     commit('setUser', data);
        // },
    },
    getters: {
        store(state) {
            return state.store;
        },
        allOrders(state) {
            return state.allOrders
        }
        
    }
  }