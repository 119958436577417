import { createRouter, createWebHistory } from '@ionic/vue-router';
import Tabs from '../views/Tabs.vue';

const routes = [
  {
    path: '/',
    redirect: '/tabs/menu'
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/profile',
    component: () => import('@/views/Profile.vue')
  },
  {
    path: '/about',
    component: () => import('@/views/About.vue')
  },
  {
    path: '/orderreview',
    component: () => import('@/views/OrderReview.vue')
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/menu'
      },
      {
        path: 'menu',
        component: () => import('@/views/Menu.vue')
      },
      {
        path: 'menu/:id',
        component: () => import('@/views/MenuItemDetails.vue')
      },      
      {
        path: 'fulfillment',
        component: () => import('@/views/Fulfillment.vue')
      },
      {
        path: 'queue',
        component: () => import('@/views/Queue.vue')
      },
      {
        path: 'userqueue',
        component: () => import('@/views/UserQueue.vue')
      },      
      {
        path: 'users',
        component: () => import('@/views/Users.vue')
      },
      {
        path: 'stock',
        component: () => import('@/views/Stock.vue')
      },
      {
        path: 'report',
        component: () => import('@/views/Report.vue')
      },
      
      {
        path: 'orderoptions',
        component: () => import('@/views/OrderOptions.vue')
      },
      {
        path: 'users/:id',
        component: () => import('@/views/UserDetails.vue')
      },
      {
        path: 'store',
        component: () => import('@/views/Store.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
