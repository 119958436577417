//import { _ } from "core-js";
import { ordersCollection } from "../../services/firebase";
import { stockCollection } from "../../services/firebase";

export default {
    state: () => ({
        orders: null,
        stockItems: null,
        notCancelled: null,
    }),
    mutations: {
        setOrders(state, data) {
            state.orders = data;
            //console.log(state.orders.length);
        },
        setStockItems(state, data) {
            state.stockItems = data;
        },
        setNotCancelled(state, data) {
            state.notCancelled = data;
        },
        changeItemStatus(state, data) {
            const order = state.orders.find(o => o.id == data.orderId);
            if(order != null) {
                const orderItem = order.items.find(i => i.id == data.orderItemId);
                if(orderItem != null) {
                    orderItem.status = data.status;
                }
                if (order.status == 'New' && data.status == 'Making') {
                    order.status = 'In Progress';
                }
                if (order.status == 'Finished' && data.status == 'Making') {
                    order.status = 'In Progress';
                }

                // var answer = [];
                // for (var i = 0; i < order.items.length; i++) {
                //     var item = order.items[i];
                //     if (item.status == 'Complete') {
                //         answer.push(order.items[i]);
                //     }
                // }
                // if (answer.length == order.items.length){
                //     order.status = 'Finished';
                // }

                //var answer = order.items.filter((item, i) => item.status == 'Complete');
                const allNew = order.items.filter(itemnew => itemnew.status == 'New');
                const answer = order.items.filter(item => item.status == 'Complete');
                const allCancelled = order.items.filter(item => item.status == 'Cancelled');
                
                if (data.status == 'New' && allNew.length == order.items.length) {
                    order.status = 'New';
                }
                
                if ((data.status == 'Complete' || data.status == 'Cancelled') && answer.length + allCancelled.length == order.items.length) {
                    order.status = 'Finished';
                }
                
                if (data.status == 'Cancelled' && allCancelled.length == order.items.length) {
                    order.status = 'Cancelled';
                }

            }

            ordersCollection.doc(order.id).set(order);

            // let order = null;
            // for(i=0; i<state.orders.length; i++)
            // {
            //     if(state.orders[i].id == data.orderId)
            //     {
            //         order = state.orders[i];
            //         break;
            //     }
            // }
        },
        changeOrderStatus(state, data) {
            const order = state.orders.find(o => o.id == data.orderId);
            order.status = data.status;

            ordersCollection.doc(order.id).set(order);
        },
        placeOrder(state, order) {
            state.orders.push(order);
        },
    },
    actions: {
        loadOrders(context) {
            // const items = [
            //   { name:'Frappuccino', description:'siurfgbhaelkrfuibgh', id: '1'},
            //   { name:'Frappuccina', description:'siurfgbhaelkrfuibgh', id: '2'},
            // ];
            const fillData = qs => {
                const orders = [];
                //console.log('fillOrderData')
                qs.forEach((doc) => {
                    const order = doc.data();
                    order.id = doc.id;
                    orders.push(order);
                });
                context.commit('setOrders', orders.filter(o => o.status != 'Cancelled' && o.status != 'Picked Up'));
                //console.log('filled');
            };

            var startOfDay = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate());
            //console.log(startOfDay);
            ordersCollection
                //.where('status', '!=', 'Picked Up')
                .where('createdDate', '>', startOfDay)
                //.where('specificDay', '=', startOfDay)
                .get().then(fillData);
            ordersCollection.where('createdDate', '>', startOfDay).onSnapshot(fillData);
            
        },


        loadStock(context) {
           var fillData = qs => {
                const stockItems = [];
                qs.forEach((doc) => {
                    const allStock = doc.data();
                    var keys = Object.keys(allStock).sort();

                    for (var i = 0; i < keys.length; i++) {
                        var key = keys[i];
                        var item = { id: i, name: key, inStock: allStock[key] };
                        stockItems.push(item);
                    }
                });
                context.commit('setStockItems', stockItems);
              };
              
              stockCollection.get('inventory').then(fillData);
              stockCollection.onSnapshot(fillData);
        },

        loadNotCancelled(context) {
            const fillData = qs => {
                const notCancelled = [];
                qs.forEach((doc) => {
                    const order = doc.data();
                    order.id = doc.id;
                    notCancelled.push(order);
                });
                context.commit('setNotCancelled', notCancelled.filter(o => o.status == 'Picked Up'));
            };
            var startOfDay = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate());
            ordersCollection.where('createdDate', '>', startOfDay).get().then(fillData);
            ordersCollection.where('createdDate', '>', startOfDay).onSnapshot(fillData);
        },
        
        // var ethansOrder = {
        //     items: [{
        //         id: 123, //menu item id
        //         name: 'Granola'
        //     }]
        // };

        // var order = {
        //     createdDate: '1/1/2021',
        //     status: 'New',
        //     items: [
        //         { id: 1, menuItemId: 123, name: 'Granola', status: 'new'}
        //     ]
        // }

        // for (var i=0; i < ethansOrder.items.length; i++) {
        //     var item = { 
        //         menuItemId: ethansOrder.items[i].id, 
        //         name: ethansOrder.items[i].name,
        //         id: i,
        //         status: 'new'
        //     }
        //     order.items.push(item)
        // }

        placeOrder(context, ethansOrder) {
            const order = {
                paid: false,
                createdDate: new Date(),
                formattedDate: new Date().getTime(),
                //specificDay: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()),
                status: "New",
                userId: context.rootGetters.loggedInUser.id,  
                userName: context.rootGetters.loggedInUser.name,   
                items: ethansOrder.items.map((i, index) => ({
                    id:  index, 
                    name: i.name,
                    menuItemId: i.id,
                    status: "New",
                    price: i.price,
                    image: i.image,
                    customizations: i.customizations.filter(c => c.added).map(c => c.name)
                }))
            };

            ordersCollection.add(order);
            //context.commit('placeOrder', order);

         },

        changeItemStatus(context, data) {
            context.commit("changeItemStatus", data);
        },

        changeOrderStatus({commit}, data) {
            commit("changeOrderStatus", data)
       },

    },
    getters: {
        orders(state) {
            return state.orders;
        },

        numberOfItemsOrderedToday(state) {
            var orders = state.orders;
            var notCancelled = state.notCancelled;
            var count = 0;

            //for (var i = 0; i < orders.length; i++)
            //{
            //    var order = orders[i];
            //}
            if (orders != null) {
                orders.forEach(order => {
                    count += order.items.filter(i => i.status != 'Cancelled').length
                });
            }
            if (notCancelled != null) {
                notCancelled.forEach(notCancelled => {
                    count += notCancelled.items.filter(i => i.status != 'Cancelled').length;
                });
            }
            //console.log(count);
            return count;
        },

        canPlaceOrder(state, getters, rootState, rootGetters) {
            if(rootGetters.store) {
                var store = rootGetters.store;
                var isStoreOpen = store.isOpen;
                var maxOpenOrders = store.maxOpenOrders;
                var numOrderedItems = getters.numberOfItemsOrderedToday;

                //&& (maxOpenOrders == null || maxOpenOrders <= numOrderedItems) -- add this back to the if statement below
                //check if store is open
                //check if we have reached max number of orders for today.
                if (isStoreOpen == true && (maxOpenOrders == null || maxOpenOrders > numOrderedItems)) {//-- add this back to the if statement below) {
                    return true;
                }
                else {
                    return false;
                }

            }
            return false;
        //return isStoreOpen && (maxOpenOrders == null || maxOpenOrders <= numOrderedItems);
        },
        outOfStockItems(state) {
            if (state.stockItems != null) {
                return state.stockItems.filter(s => s.inStock == false).map(s => s.name); //['Vanilla', 'Fruit'];
            }
            return [];
        },
        stockItems(state) {
            return state.stockItems;
        },
        // stockItem(state) {
        //     return (id) => {
        //       if (state.stockItems) {
        //         return state.stockItems.find(item => item.id === id);
        //       }
        //     };
        //   },
    }
  }