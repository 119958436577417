import {auth} from '../../services/firebase';
import { usersCollection } from '../../services/firebase';

export default {

    state: () => ({
        loggedInUser: null,
        users: null
    }),
    mutations: {
        setLoggedInUser(state, data) {
            state.loggedInUser = data;
        },
        setUsers(state, data){
            state.users = data;
        }
    },
    actions: {
        async setLoggedInUser({commit}, user) {
            
            const doc = await usersCollection.doc(user.id).get();
            
            if (doc.exists) {
                const data = doc.data();
                user.admin = data.admin == true;
                user.barista = data.barista == true;
            } else {
                user.admin = false;
                user.barista = false;
            }
            usersCollection.doc(user.id).set({
                name: user.name,
                photoURL: user.photoURL
            }, {merge: true});

            commit('setLoggedInUser', user);
        },
        loadUsers(context) {
            
            var fillData = qs => {
                const users = [];
                console.log('filling users...');
                qs.forEach((doc) => {
                    const user = doc.data();
                    user.id = doc.id;
                    users.push(user);
                });
                context.commit('setUsers', users.sort((a, b) => (a.name > b.name) ? 1 : -1));
            };

            usersCollection.get().then(fillData);
            usersCollection.onSnapshot(fillData);
            //add snapshot
          },
          saveUser(context, user){
              console.log("savingUser");
            usersCollection.doc(user.id).set({
                name: user.name,
                admin: user.admin == true,
                barista: user.barista == true
            }, {merge: true});
          },

        signout({commit}) {
            auth.signOut();
            commit('setLoggedInUser', null);
        }

    },
    getters: {
        loggedInUser(state) {
            return state.loggedInUser;
        },
        users(state) {
            return state.users;
        },
        findUser(state) {
            return (id) => {
              if (state.users) {
                return state.users.find(item => item.id === id);
              }
            };
        }
    }
  }