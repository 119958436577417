import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import {auth} from './services/firebase';
import { IonCardTitle, IonicVue, IonCardContent } from '@ionic/vue';
import BaseLayout from './views/base/BaseLayout.vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import store from './store';

import { IonItemDivider, IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonGrid, IonRow, IonCol, IonSelect, IonSelectOption, IonModal } from '@ionic/vue';
import { IonChip, IonItemGroup, IonBadge, IonToggle, IonDatetime, IonItem, IonThumbnail, IonImg, IonTabBar, IonInput, IonTabButton, IonTabs, IonLabel, IonIcon, IonButton, IonBackButton, IonButtons, IonAvatar, IonCardSubtitle, IonCardHeader, IonCard} from '@ionic/vue';

/* Theme variables */
import './theme/variables.css';

import './registerServiceWorker'

//guarding routes 
var baristaRoutes = ['/fulfillment', '/queue', '/report', '/store' ];
var adminRoutes = ['/users' ];
router.beforeEach((to, from, next) => {
  if(to.path.indexOf('login') == -1){
    if(auth.currentUser == null){
      next({ path: '/login'});
      return;
    } else {
      const isAdmin = store.getters.loggedInUser != null && store.getters.loggedInUser.admin == true;
      const isBarista = store.getters.loggedInUser != null && store.getters.loggedInUser.barista == true;
      if (adminRoutes.find(r => to.path.indexOf(r) > -1) != null)
      {
        if (!isAdmin) {
          next({ path: '/tabs/menu'});
          return;
        }
      }
      if (baristaRoutes.find(r => to.path.indexOf(r) > -1) != null)
      {
        if (!isBarista && !isAdmin) {
          next({ path: '/tabs/menu'});
          return;
        }
      }
    }
  }
  next();
});

var initialized = false
auth.onAuthStateChanged(async user =>{
  console.log('onAuthStateChanged:' + user);
  if (user != null) {
    store.dispatch('setLoggedInUser', {id: user.uid, name: user.displayName, email: user.email, photoURL: user.photoURL})
   } else {
    store.dispatch('setLoggedInUser', null);
   }
  if (initialized == false)  {
    setTimeout(() =>{
    initApp();
    initialized = true;
    document.getElementById('splash').style.display = 'none';
  }, 1500);
  }
});
function initApp()
{
  console.log('initApp');
  const app = createApp(App)
    .use(store)
    .use(router)
    .use(IonicVue);

  app.component('base-layout', BaseLayout);
  app.component('ion-page', IonPage);
  app.component('ion-tab-bar', IonTabBar);
  app.component('ion-tabs', IonTabs);
  app.component('ion-label', IonLabel);
  app.component('ion-label', IonLabel);
  app.component('ion-icon', IonIcon);
  app.component('ion-tab-button', IonTabButton);
  app.component('ion-list', IonList);
  app.component('ion-header', IonHeader);
  app.component('ion-toolbar', IonToolbar);
  app.component('ion-title', IonTitle);
  app.component('ion-content', IonContent);
  app.component('ion-grid', IonGrid);
  app.component('ion-row', IonRow);
  app.component('ion-col', IonCol);
  app.component('ion-button', IonButton);
  app.component('ion-back-button', IonBackButton);
  app.component('ion-buttons', IonButtons);
  app.component('ion-avatar', IonAvatar);
  app.component('ion-card-subtitle', IonCardSubtitle);
  app.component('ion-card-title', IonCardTitle);
  app.component('ion-card-header', IonCardHeader);
  app.component('ion-card', IonCard);
  app.component('ion-card-content', IonCardContent);
  app.component('ion-img', IonImg);
  app.component('ion-thumbnail', IonThumbnail);
  app.component('ion-item-divider', IonItemDivider);
  app.component('ion-item', IonItem);
  app.component('ion-datetime', IonDatetime);
  app.component('ion-toggle', IonToggle); 
  app.component('ion-badge', IonBadge);
  app.component('ion-chip', IonChip);
  app.component('ion-item-group', IonItemGroup);
  app.component('ion-input', IonInput);
  app.component('ion-select', IonSelect);
  app.component('ion-select-option', IonSelectOption);
  app.component('ion-modal', IonModal);
  
  store.dispatch('loadStore');
  store.dispatch('loadMenu');
  router.isReady().then(() => {
    app.mount('#app');
  });
}