import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyB34O2xr9adPEYGij6K4zO35NSid6zvhXo",
    authDomain: "acsdailygrind.firebaseapp.com",
    projectId: "acsdailygrind",
    storageBucket: "acsdailygrind.appspot.com",
    messagingSenderId: "771795568021",
    appId: "1:771795568021:web:8e145bb4c75420e0cb629c"
  };

  firebase.initializeApp(firebaseConfig);


  const db = firebase.firestore();
  const auth = firebase.auth();

  const menuItemsCollection = db.collection('menu');
  const ordersCollection = db.collection('orders');
  const usersCollection = db.collection('users');
  const stockCollection = db.collection('stock');
  const storeCollection = db.collection('store');

  export {
      db,
      auth,
      usersCollection,
      menuItemsCollection,
      ordersCollection,
      stockCollection,
      storeCollection
  }