<template>
    <ion-page>
      <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start" v-if="pageDefaultBackLink">      
                <ion-back-button :default-href="pageDefaultBackLink"></ion-back-button>
                
            </ion-buttons> 
            <ion-buttons slot="start"><ion-button type="button" router-link="/about" >
            
                                <img width="30" src="/assets/DailyGringLogo.png"/>
                      
                            </ion-button></ion-buttons>
            <ion-buttons slot="end">
               <!-- Store Open <ion-toggle v-if="store" v-model="isOpen"  @ionChange="toggleStore"></ion-toggle> -->
              <ion-button type="button" router-link="/profile" ><ion-icon slot="start" :icon="personCircleOutline"></ion-icon></ion-button>

            <ion-button type="button" router-link="/orderreview">
               <ion-icon slot="start" :icon="cartOutline"></ion-icon>
               <ion-badge color="danger" v-if="orderItems > 0">
                  {{orderItems}} 
               </ion-badge>
            </ion-button>
            </ion-buttons>
          <ion-title>{{title}}</ion-title>

          
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen>
        
          <slot />
        
      </ion-content>
  </ion-page>
</template>

<script>
//import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonIcon, IonButton, IonBackButton } from '@ionic/vue';
import { personCircleOutline, cartOutline} from 'ionicons/icons';
export default {
    components: {
        
    },
    data() {
        return {
            personCircleOutline, cartOutline,
        }
    },
    computed: {
      orderItems() {
        if (this.$store.getters.orderReview) {
          return this.$store.getters.orderReview.items.length;
        }
        return 0;
      },
      isOpen() {
        if(this.store != null && this.store.isOpen == true) {
            return true;
        }
        else {
          return false;
        }
        //return this.store != null && this.store.isOpen;
      },
      store() {
        return this.$store.getters.store;
      },
      currentUser() {
            return this.$store.getters.loggedInUser;
        }
    },
    methods: {
      toggleStore() {
        this.$store.dispatch('toggleStoreOpen', !this.isOpen);
      }
    },
    props: ['title', 'pageDefaultBackLink']
}
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}

ion-badge {
  font-size: 8px;
}
</style>
