import { menuItemsCollection, stockCollection } from '../../services/firebase';

export default {
    state: () => ({
      nextId: 1,
      menuItems: null,
      orderReview: {
        id: null,
        name: '',
        items: [],
      }
    }),
    mutations: {
      setMenuItems(state, data) {
          state.menuItems = data;
      },
      setOrderName(state, data) {
          if (state.orderReview.id == null) {
            state.orderReview.id = state.nextId;
            state.nextId++;
          }
          state.orderReview.name = data.name;
      },
      addOrderItem(state, item) {
        state.nextId++;
        item.id = state.nextId;
        state.orderReview.items.push(item);
      },
      emptyOrderReview(state) {
        state.orderReview.items = [];
      },
      removeOrderReviewItem(state, item) {
        const valueToRemove = item;
        const filteredItems = state.orderReview.items.filter(item => item.id !== valueToRemove.id);
        state.orderReview.items = filteredItems;
      },
    },
    actions: {
      loadMenu(context) {
        // const items = [
        //   { name:'Frappuccino', description:'siurfgbhaelkrfuibgh', id: '1'},
        //   { name:'Frappuccina', description:'siurfgbhaelkrfuibgh', id: '2'},
        // ];
        
        var fillData = qs => {
          const menuItems = [];
          qs.forEach((doc) => {
              const menuItem = doc.data();
              menuItem.id = doc.id;
              menuItems.push(menuItem);
          });
          context.commit('setMenuItems', menuItems);
        };
        menuItemsCollection.get().then(fillData);
        menuItemsCollection.onSnapshot(fillData);

      },
      addOrderReviewItem(context, item) {
        context.commit('setOrderName', { name: context.rootGetters.loggedInUser.name });
        context.commit('addOrderItem', item);
      },
      emptyOrderReview(context) {
        context.commit('emptyOrderReview');
      },
      removeOrderReviewItem(context, item) {
        context.commit('removeOrderReviewItem', item);
      },

      markStock(context, data) {
        /*existing Inventory = {
          Beans: true,
          Vanilla: false,
          Bacon: true
        }
        */
        //data = { stockName: 'Bacon', inStock: false };
        
        stockCollection.doc('inventory').set(data, {merge: true});
        context.dispatch('loadMenu');
      }

    },
    getters: {
      menuItems(state, getters, rootState, rootGetters) {
        var outOfStockItems = rootGetters.outOfStockItems;
        if (state.menuItems != null) {
          return state.menuItems.filter(f => !outOfStockItems.includes(f.name));
        }
        return [];
      },
      menuItem(state) {
        return (id) => {
          if (state.menuItems) {
            var mi = state.menuItems.find(item => item.id === id);
            return {...mi};
          }
        };
      },
      orderReview(state) {
        return state.orderReview;
      },
      
    }
  }