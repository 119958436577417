<template>
  <ion-app>
    <ion-router-outlet />
      <ion-modal @onDidDismiss="showModal=false" :is-open="showModal">
        <ion-page>
          <ion-header translucent>
            <ion-toolbar>
              <ion-title>Install Daily Grind</ion-title>
              <ion-buttons slot="end">
                <ion-button @click="showModal=false">Close</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>

          <ion-content>
            <ion-button @click="dismiss">Dismiss</ion-button>
            <ion-button text @click="install">Install</ion-button>
          </ion-content>
        </ion-page>
      </ion-modal>

  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';

export default {
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  data() {
    return {
      deferredPrompt: null,
      showModal: false,
    };
  },
  created() {
    console.log('_dg exists?')
    console.log(window._dg);
    if (window._dg && window._dg.deferredPrompt) {
      this.showModal = true;
    }
    // window.addEventListener("beforeinstallprompt", e => {
    //   e.preventDefault();
    //   // Stash the event so it can be triggered later.
    //   this.deferredPrompt = e;
    //   this.showModal = true;
    // });
    // window.addEventListener("appinstalled", () => {
    //   this.deferredPrompt = null;
    // });
  },
  methods: {
    async dismiss() {
      window._dg.deferredPrompt = null;
      this.showModal = false;
    },
    async install() {
      window._dg.deferredPrompt.prompt();
      this.showModal = false;
    }
  }
};
</script>