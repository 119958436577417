<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="menu" href="/tabs/menu">
          <ion-icon :icon="readerOutline" />
          <ion-label>Menu</ion-label>
        </ion-tab-button>
          
        <ion-tab-button tab="Fulfillment" href="/tabs/fulfillment" v-if="currentUser != null && currentUser.barista">
          <ion-icon :icon="checkboxOutline" />
          <ion-label>Fulfillment</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="queue" href="/tabs/queue"  v-if="currentUser != null && currentUser.barista">
          <ion-icon :icon="clipboardOutline" />
          <ion-label>Queue</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="userqueue" href="/tabs/userqueue">
          <ion-icon :icon="timeOutline" />
          <ion-label>My Order</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="store" href="/tabs/store" v-if="currentUser != null && currentUser.admin">
          <ion-icon :icon="storefrontOutline" />
          <ion-label>store</ion-label>
        </ion-tab-button>
        <!-- <ion-tab-button tab="users" href="/tabs/users" v-if="currentUser != null && currentUser.admin">
          <ion-icon :icon="peopleCircleOutline" />
          <ion-label>Users</ion-label>
        </ion-tab-button> -->
        <!-- <ion-tab-button tab="report" href="/tabs/report" v-if="currentUser != null && currentUser.admin">
          <ion-icon :icon="receiptOutline" />
          <ion-label>Report</ion-label>
        </ion-tab-button> -->
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
//import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { ellipse, square, triangle, readerOutline, clipboardOutline, cartOutline, checkboxOutline, storefrontOutline, peopleCircleOutline, timeOutline, receiptOutline } from 'ionicons/icons';

export default {
  name: 'Tabs',
  //components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    return {
      ellipse, 
      square, 
      triangle,
      readerOutline,
      clipboardOutline,
      cartOutline,
      checkboxOutline,
      storefrontOutline,
      peopleCircleOutline,
      timeOutline,
      receiptOutline
    }
  },
  computed: {
    currentUser() {
        return this.$store.getters.loggedInUser;
    }
  },  
}
</script>