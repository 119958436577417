import user from './user';
import menu from './menu';
import fulfillment from './fulfillment';
import queue from './queue';
import { createStore } from 'vuex';

const store = createStore({
    modules: {
      user,
      menu,
      fulfillment,
      queue
    }
})

export default store;